body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212 !important;
}

@font-face {
  font-family: BrandonGrotesque;
  src: url("fonts/Brandon_med.otf") format("opentype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Question {
  padding-top: 30px;
  color: #dcdcdc;
  font-weight: 500;
  text-align: center;
  line-height: 1.5;
  font-size: 1.8em;
  font-family: BrandonGrotesque, Open Sans, Helvetica Neue, Arial;
}

.Options-col {
  margin: auto;
  display: flex;
  outline: 0;
  border-radius: 4px;
  color: #dcdcdc;
}

.Options {
  margin-top: 5vh;
  margin-left: 20%;
}

.Options-Right {
  margin-top: 5vh;
  max-width: 20vw;
}

.background {
  height: 200vh;
  width: 100vw;
  position: absolute;
  top: -20vh;
}

.Card-Disappear {
  padding: 2vh 5vw 5vh 5vw;
  margin: 30vh 10vw 30vh 10vw;
  border-radius: 3vh;
}

.Card-Disappear.index0 {
  padding: 2vh 5vw 5vh 5vw;
  margin: 0vh 10vw 30vh 10vw;
  border-radius: 3vh;
}

::-webkit-scrollbar {
  display: none;
}

.white-box-wrapper {
  width: 100vw;
  height: 10vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: sticky;
  top: 0;
  background: white;
  max-width: 100vw;
  z-index: -2;
}

.preamble-white-box {
  transform-origin: top left;
  background: white;
  max-width: 100vw;
  height: 50vh;
  z-index: -1;
  padding: 3vh;
  position: relative;
  bottom: 10vh;
}

.submit-white-box {
  padding: 3vh;
  text-align: center;
}

.CTO-self-assessment {
  position: relative;
}

.white-box-wrapper-results {
  width: 100vw;
  max-height: 500vh;
  min-height: 100vh;
  background: #f1f1f1;
  z-index: 5;
  transition: 1s ease;
}

.white-box-wrapper-submit {
  background: white;
  width: 100vw;
  max-height: 500vh;
  min-height: 100vh;
  background: white;
  z-index: 5;
  transition: 1s ease;
}

.Logo {
  max-width: 3%;
  margin-bottom: 3vh;
  float: right;
}

.SevenCTOs {
  max-width: 8%;
  margin-left: 0vw;
  position: absolute;
}

.text1 {
  margin-left: 22vw;
}

.points {
  margin-top: 6vh;
  display: inline-block;
}

.DuelBigLogo {
  position: relative;
  bottom: 25vh;
  max-width: 14%;
  margin-right: 4vw;
  float: right;
}

.BigLogo {
  position: relative;
  bottom: 34vh;
  max-width: 14vw;
  margin-right: 8vw;
  float: right;
}

.results-wrapper {
  margin: auto;
  width: 55%;
  padding: 10px;
}

.Title {
  /* font-size: 8vh; */
  font-weight: 900;
  text-align: left;
  margin-top: 1.2vh;
  margin-right: 25vw;
  /* font-family: BrandonGrotesque; */
  position: relative;
  /* float: right;  */
}

.Popups {
  max-width: 35vw;
}

@media screen and (max-width: 1000px) {
  .SevenCTOs {
    max-width: 25%;
    margin-left: 2vw;
    margin-bottom: 1vh;
    position: relative;
  }

  .Options-col {
    min-width: 75vw;
    display: flex;
    color: #dcdcdc;
  }

  .results-wrapper {
    margin: 10px, 10px, 10px, 10px;
    width: 100%;
  }

  .Title {
    /* font-size: 8vh; */
    font-weight: 700;
    text-align: left;
    position: relative;
    margin-left: 3vw;
    /* font-family: BrandonGrotesque; */
  }

  .points {
    margin-top: 0vh;
    display: inline-block;
  }

  .preamble-white-box {
    transform-origin: top left;
    background: white;
    max-width: 100vw;
    height: 100vh;
    z-index: -1;
    padding: 3vh;
    position: relative;
    bottom: 10vh;
  }

  .Options-Right {
    margin-top: 5vh;
    margin-left: auto;
  }

  .Options {
    margin-top: 5vh;
    margin-left: auto;
  }

  .Question {
    padding-top: 30px;
    color: #dcdcdc;
    font-weight: 500;
    text-align: center;
    line-height: 1.5;
    font-size: 1.5em;
    font-family: BrandonGrotesque, Open Sans, Helvetica Neue, Arial;
  }

  .Logo {
    max-width: 8%;
    margin-bottom: 3vh;
    float: right;
  }

  .white-box-wrapper {
    width: 100vw;
    height: 10vh;
    overflow-y: scroll;
    overflow-x: hidden;
    position: sticky;
    top: 0;
    background: white;
    max-width: 100vw;
    z-index: -2;
  }

  .Card-Disappear {
    padding: 2vh 5vw 5vh 5vw;
    margin: 43vh 10vw 30vh 10vw;
    border-radius: 3vh;
  }

  .Popups {
    max-width: 100vw;
  }

  .text1 {
    margin-left: 0vw;
  }
}

.sendEmail {
  text-align: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.EmailSVG {
  height: 27vh;
  display: block;
  margin-top: 3vh;
  margin-left: auto;
  margin-right: auto;
}

/* .Results {
  max-height: 100vh !important;
}
 */

.background-short{
  height: 101vh;
  width: 100vw;
  position: absolute;
}

.Card-Disappear.index0.short {
  padding: 15vh 5vw 5vh 5vw;
  margin: 0vh 10vw 30vh 10vw;
  border-radius: 3vh;
}

.progress-wrapper-short{
  overflow-y: scroll;
  overflow-x: hidden;
  position: sticky;
  top: 0;
  z-index: -2;
}

.white-box-wrapper-short{
  width: 100vw;
  height: 10vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background: white;
  max-width: 100vw;
  z-index: 0;
  margin-top: -10vh;
}
